
import { defineComponent, reactive } from "vue";
import BaseCard from "@/app/core/components/cards/BaseCard.vue";
import BaseInput from "@/app/core/components/forms/BaseInput.vue";
import { useProfileModule } from "@/store/modules/ProfileModule";
import { container } from "tsyringe";
import { BillingService } from "@/app/core/services/BillingService";
import { BillingUpdateRequestContract } from "@/app/core/interfaces/services/billing/BillingUpdateRequestContract";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { Billing } from "@/app/core/models/domain/Billing";
import router from "@/router/Router";

export default defineComponent({
  name: "BillingInfo",
  components: { BaseInput, BaseCard },
  async setup() {
    const profileCtx = useProfileModule();
    const billingService: BillingService = container.resolve(BillingService);

    if (!profileCtx.state.billing) {
      profileCtx.actions.fetchBilling();
    }

    const updateBillingForm = reactive<BillingUpdateRequestContract>({
      contact: profileCtx.getters.getBilling?.contact,
      address: profileCtx.getters.getBilling?.address,
      city: profileCtx.getters.getBilling?.city,
      state: profileCtx.getters.getBilling?.state,
      zip: profileCtx.getters.getBilling?.zip,
      phone: profileCtx.getters.getBilling?.phone,
      email: profileCtx.getters.getBilling?.email,
    });

    const rules = {
      contact: {},
      address: {},
      city: {},
      state: {},
      zip: {},
      phone: {},
      email: {
        email,
      },
    };

    const v$ = useVuelidate(rules, updateBillingForm as any);

    const updateBilling = () => {
      v$.value.$touch();
      if (!v$.value.$invalid) {
        billingService
          .update<BillingUpdateRequestContract, Billing>(updateBillingForm)
          .then((res) => {
            profileCtx.mutations.setBilling(res);
            router.push({ name: "Billing" });
          });
      }
    };
    return {
      updateBilling,
      updateBillingForm,
      v$,
    };
  },
});
